import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 813.98051 770.90543"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#ffe760",
      d: "m53.62412,171.44814c173.67892-224.33074,465.68289-232.82485,685.55322,0,219.87032,232.82485-90.59111,646.76572-342.79319,595.02841C144.18207,714.73923-114.54217,388.6499,53.62412,171.44814"
    }, null, -1)
  ])))
}
export default { render: render }